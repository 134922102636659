import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Container,
  VStack,
  Button,
  Image,
  keyframes,
  Stack,
  IconButton,
  Link as ChakraLink,
  Grid,
  Badge,
  Divider,
  useBreakpointValue,
  chakra,
  HStack,
  SimpleGrid,
  useColorModeValue,
  Tag,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { Link as RouterLink } from "react-router-dom";
import maximoAiLogo from "../images/maximo-ai-logo-circle.png";
import {
  FaInstagram,
  FaTwitter,
  FaTelegramPlane,
  FaRobot,
  FaChartLine,
  FaPen,
  FaLightbulb,
  FaRocket,
  FaCog,
  FaKey,
  FaShieldAlt,
  FaExternalLinkAlt,
} from "react-icons/fa";

// Animation keyframes
const pulseAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(49, 151, 149, 0.7); }
  70% { box-shadow: 0 0 0 30px rgba(49, 151, 149, 0); }
  100% { box-shadow: 0 0 0 0 rgba(49, 151, 149, 0); }
`;

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-15px); }
  100% { transform: translateY(0px); }
`;

const glowAnimation = keyframes`
  0% { text-shadow: 0 0 10px rgba(80, 227, 194, 0.1); }
  50% { text-shadow: 0 0 20px rgba(80, 227, 194, 0.5), 0 0 30px rgba(80, 227, 194, 0.3); }
  100% { text-shadow: 0 0 10px rgba(80, 227, 194, 0.1); }
`;

const blinkAnimation = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.7; }
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const shimmerAnimation = keyframes`
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
`;

// Motion components
const MotionBox = motion(Box);
const MotionFlex = motion(Flex);
const MotionText = motion(Text);
const MotionHeading = motion(Heading);
const MotionImage = motion(Image);
const MotionButton = motion(Button);
const MotionTag = motion(Tag);

// Animated components
const AnimatedGradientBox = chakra(MotionBox, {
  baseStyle: {
    background:
      "linear-gradient(135deg, #121212 0%, #1e1e1e 25%, #1a1a1a 50%, #121212 75%, #1e1e1e 100%)",
    backgroundSize: "400% 400%",
    animation: `${gradientAnimation} 15s ease infinite`,
  },
});

const AnimatedLogo = () => (
  <Box
    as={motion.div}
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.8 }}
    position="relative"
    width={{ base: "220px", md: "300px" }}
    height={{ base: "220px", md: "300px" }}
    animation={`${floatAnimation} 6s ease-in-out infinite`}
  >
    <Image
      src={maximoAiLogo}
      alt="Maximo AI Logo"
      width="100%"
      height="100%"
      objectFit="cover"
      as={motion.img}
      animate={{
        rotate: [0, 360],
      }}
      transition={{
        duration: 30,
        repeat: Infinity,
        ease: "linear",
      }}
      borderRadius="full"
      zIndex={2}
      filter="drop-shadow(0 0 20px rgba(49, 151, 149, 0.6))"
    />
    <Box
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      borderRadius="full"
      animation={`${pulseAnimation} 3s infinite`}
      zIndex={1}
    />
  </Box>
);

const FeatureCard = ({ title, description, icon, delay }) => (
  <MotionBox
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay }}
    viewport={{ once: true }}
    whileHover={{ scale: 1.05, y: -5 }}
    p={8}
    bg="rgba(22, 24, 29, 0.8)"
    backdropFilter="blur(10px)"
    borderRadius="2xl"
    boxShadow="0 10px 30px -10px rgba(0, 0, 0, 0.5)"
    textAlign="center"
    borderWidth="1px"
    borderColor="gray.700"
    position="relative"
    overflow="hidden"
    _before={{
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "4px",
      background: "linear-gradient(90deg, #319795, #38B2AC)",
      borderTopLeftRadius: "2xl",
      borderTopRightRadius: "2xl",
    }}
  >
    <Flex
      justify="center"
      align="center"
      w="60px"
      h="60px"
      borderRadius="full"
      bg="rgba(49, 151, 149, 0.2)"
      mb={5}
      mx="auto"
      as={motion.div}
      whileHover={{ rotate: 360 }}
      transition={{ duration: 1 }}
    >
      <Box color="teal.300" fontSize="2xl">
        {icon}
      </Box>
    </Flex>
    <Heading as="h3" size="md" mb={4} color="white">
      {title}
    </Heading>
    <Text color="gray.300" fontSize="md">
      {description}
    </Text>
    <MotionBox
      position="absolute"
      bottom="0"
      left="0"
      height="3px"
      bg="linear-gradient(90deg, #319795, #38B2AC)"
      initial={{ width: "0%" }}
      whileInView={{ width: "100%" }}
      transition={{ duration: 1, delay: delay + 0.3 }}
    />
  </MotionBox>
);

const StatsItem = ({ number, label, delay }) => (
  <MotionBox
    initial={{ opacity: 0, scale: 0.9 }}
    whileInView={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.4, delay }}
    textAlign="center"
    p={5}
    borderRadius="xl"
    bg="rgba(26, 32, 44, 0.5)"
    borderWidth="1px"
    borderColor="gray.700"
    backdropFilter="blur(5px)"
  >
    <MotionText
      fontSize={{ base: "3xl", md: "5xl" }}
      fontWeight="extrabold"
      bgGradient="linear(to-r, teal.300, teal.200)"
      backgroundClip="text"
      initial={{ y: 20 }}
      whileInView={{ y: 0 }}
      transition={{ duration: 0.5, delay: delay + 0.1 }}
      mb={2}
      animation={`${blinkAnimation} 3s infinite`}
    >
      {number}
    </MotionText>
    <Text fontSize="md" color="gray.400">
      {label}
    </Text>
  </MotionBox>
);

const TestimonialCard = ({ name, role, quote, delay }) => (
  <MotionBox
    initial={{ opacity: 0, x: -20 }}
    whileInView={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.6, delay }}
    bg="rgba(26, 32, 44, 0.7)"
    p={6}
    borderRadius="lg"
    boxShadow="lg"
    borderWidth="1px"
    borderColor="gray.700"
    position="relative"
    backdropFilter="blur(10px)"
    _hover={{
      transform: "translateY(-5px)",
      boxShadow: "0 15px 30px -10px rgba(0, 0, 0, 0.3)",
      borderColor: "teal.700",
    }}
    as={motion.div}
    whileHover={{ y: -5 }}
  >
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      height="100%"
      background="linear-gradient(90deg, rgba(49, 151, 149, 0) 0%, rgba(49, 151, 149, 0.1) 50%, rgba(49, 151, 149, 0) 100%)"
      backgroundSize="200% 100%"
      animation={`${shimmerAnimation} 3s infinite linear`}
      pointerEvents="none"
      opacity="0.5"
    />
    <Text fontSize="lg" color="gray.300" mb={4} fontStyle="italic">
      "{quote}"
    </Text>
    <Flex align="center">
      <Box
        w="10px"
        h="10px"
        borderRadius="full"
        bg="teal.400"
        mr={3}
        as={motion.div}
        animate={{ scale: [1, 1.2, 1] }}
        transition={{ duration: 2, repeat: Infinity }}
      />
      <Box>
        <Text fontWeight="bold" color="white">
          {name}
        </Text>
        <Text fontSize="sm" color="gray.400">
          {role}
        </Text>
      </Box>
    </Flex>
  </MotionBox>
);

const Home = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <AnimatedGradientBox minH="100vh">
      {/* Decorative elements */}
      <Box
        position="absolute"
        top="20%"
        left="5%"
        width="300px"
        height="300px"
        borderRadius="full"
        bg="radial-gradient(circle, rgba(49, 151, 149, 0.07) 0%, rgba(0, 0, 0, 0) 70%)"
        zIndex="0"
        as={motion.div}
        animate={{ scale: [1, 1.2, 1] }}
        transition={{ duration: 10, repeat: Infinity }}
      />

      <Box
        position="absolute"
        bottom="10%"
        right="5%"
        width="400px"
        height="400px"
        borderRadius="full"
        bg="radial-gradient(circle, rgba(49, 151, 149, 0.05) 0%, rgba(0, 0, 0, 0) 70%)"
        zIndex="0"
        as={motion.div}
        animate={{ scale: [1, 1.3, 1] }}
        transition={{ duration: 15, repeat: Infinity }}
      />

      <Box
        position="absolute"
        top="60%"
        left="10%"
        width="200px"
        height="200px"
        borderRadius="full"
        bg="radial-gradient(circle, rgba(99, 179, 237, 0.05) 0%, rgba(0, 0, 0, 0) 70%)"
        zIndex="0"
        as={motion.div}
        animate={{ scale: [1, 1.1, 1] }}
        transition={{ duration: 7, repeat: Infinity }}
      />

      {/* Hero Section */}
      <Container
        maxW="container.xl"
        py={{ base: 14, md: 20 }}
        position="relative"
        zIndex={1}
      >
        <VStack spacing={{ base: 12, md: 20 }} align="stretch">
          <Flex
            direction={{ base: "column", md: "row" }}
            align="center"
            justify="space-between"
            position="relative"
          >
            <MotionBox
              flex={1}
              mr={{ base: 0, md: 10 }}
              mb={{ base: 10, md: 0 }}
              zIndex="1"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7 }}
            >
              <MotionTag
                size="lg"
                colorScheme="teal"
                fontSize="md"
                px={3}
                py={2}
                borderRadius="full"
                mb={6}
                whileHover={{ scale: 1.05 }}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.7, delay: 0.1 }}
                fontWeight="bold"
                variant="subtle"
              >
                Next-Gen AI Solutions
              </MotionTag>

              <MotionHeading
                as="h1"
                size="2xl"
                mb={6}
                color="white"
                lineHeight={{ base: "1.3", md: "1.2" }}
                fontWeight="extrabold"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.7, delay: 0.2 }}
                animation={`${glowAnimation} 4s infinite`}
                letterSpacing="-0.02em"
              >
                Elevate Your Business with{" "}
                <Text
                  as="span"
                  bgGradient="linear(to-r, teal.400, teal.200)"
                  backgroundClip="text"
                  fontWeight="extrabold"
                >
                  Maximo AI
                </Text>
              </MotionHeading>

              <MotionText
                fontSize={{ base: "lg", md: "xl" }}
                mb={8}
                color="gray.300"
                lineHeight="tall"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.7, delay: 0.4 }}
                maxW="570px"
              >
                Discover the future of digital strategy with AI-powered trading,
                content creation, and automation tools designed for tomorrow's
                business landscape.
              </MotionText>

              <HStack spacing={4}>
                <MotionButton
                  as={RouterLink}
                  to="/endpoints"
                  size="lg"
                  bgGradient="linear(to-r, teal.500, teal.400)"
                  color="white"
                  fontWeight="bold"
                  borderRadius="full"
                  px={8}
                  py={7}
                  _hover={{
                    bgGradient: "linear(to-r, teal.400, teal.300)",
                    transform: "translateY(-2px)",
                    boxShadow: "0 15px 30px -10px rgba(49, 151, 149, 0.4)",
                  }}
                  _active={{
                    bgGradient: "linear(to-r, teal.600, teal.500)",
                  }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7, delay: 0.6 }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  leftIcon={<FaRocket />}
                >
                  Explore Our API
                </MotionButton>

                <MotionButton
                  as="a"
                  href="https://maximoai.co/platform"
                  target="_blank"
                  size="lg"
                  bg="rgba(26, 32, 44, 0.7)"
                  color="teal.300"
                  fontWeight="bold"
                  borderRadius="full"
                  px={8}
                  py={7}
                  border="1px solid"
                  borderColor="teal.700"
                  _hover={{
                    bg: "rgba(49, 151, 149, 0.2)",
                    color: "teal.200",
                    transform: "translateY(-2px)",
                    boxShadow: "0 10px 20px -10px rgba(49, 151, 149, 0.3)",
                  }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7, delay: 0.7 }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  leftIcon={<FaKey />}
                >
                  Get API Key
                </MotionButton>
              </HStack>
            </MotionBox>

            <Box
              flex={1}
              maxW={{ base: "100%", md: "450px" }}
              display="flex"
              justifyContent="center"
              zIndex="1"
            >
              <AnimatedLogo />
            </Box>
          </Flex>

          {/* Stats Section */}
          <MotionBox
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
            viewport={{ once: true }}
          >
            <SimpleGrid
              columns={{ base: 1, sm: 3 }}
              spacing={{ base: 4, md: 10 }}
              width="full"
              py={5}
            >
              <StatsItem number="99.9%" label="Uptime" delay={0.1} />
              <StatsItem number="100K+" label="API Calls" delay={0.2} />
              <StatsItem number="150+" label="Active Users" delay={0.3} />
            </SimpleGrid>
          </MotionBox>

          {/* Services Section */}
          <VStack spacing={10}>
            <MotionHeading
              as="h2"
              size="xl"
              textAlign="center"
              color="white"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              letterSpacing="-0.02em"
            >
              Our Powerful{" "}
              <Text
                as="span"
                bgGradient="linear(to-r, teal.400, teal.200)"
                backgroundClip="text"
              >
                Services
              </Text>
            </MotionHeading>

            <MotionText
              textAlign="center"
              color="gray.400"
              maxW="700px"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.1 }}
              viewport={{ once: true }}
              mb={5}
              fontSize="lg"
            >
              Transform your business with state-of-the-art AI solutions
              designed for the modern digital landscape
            </MotionText>

            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="100%">
              <FeatureCard
                title="AI-Powered Trading"
                description="Leverage advanced algorithms and real-time analysis for smarter, data-driven trading decisions."
                icon={<FaChartLine />}
                delay={0.1}
              />
              <FeatureCard
                title="Content Creation"
                description="Generate high-quality, engaging content with our AI-powered tools that understand your brand voice."
                icon={<FaPen />}
                delay={0.2}
              />
              <FeatureCard
                title="Automation Tools"
                description="Streamline your workflows and boost productivity with intelligent automation solutions."
                icon={<FaRobot />}
                delay={0.3}
              />
            </SimpleGrid>

            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              spacing={8}
              w="100%"
              mt={4}
            >
              <FeatureCard
                title="Strategic Insights"
                description="Gain valuable business intelligence and predictive analytics to stay ahead of market trends."
                icon={<FaLightbulb />}
                delay={0.4}
              />
              <FeatureCard
                title="Custom AI Solutions"
                description="Tailored AI implementations designed specifically for your unique business requirements."
                icon={<FaCog />}
                delay={0.5}
              />
            </SimpleGrid>
          </VStack>

          {/* Testimonials */}
          <VStack spacing={10} mt={10}>
            <MotionHeading
              as="h2"
              size="xl"
              textAlign="center"
              color="white"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              letterSpacing="-0.02em"
            >
              What Our Clients Say
            </MotionHeading>

            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6} w="100%">
              <TestimonialCard
                name="Sarah Johnson"
                role="CEO, TechForward"
                quote="Maximo AI has completely transformed how we approach market analysis. The insights are invaluable."
                delay={0.1}
              />
              <TestimonialCard
                name="Michael Chen"
                role="Marketing Director"
                quote="The content generation capabilities are remarkable. We've increased our output by 300% while maintaining quality."
                delay={0.2}
              />
              <TestimonialCard
                name="Elena Rodriguez"
                role="Head of Operations"
                quote="Automation that actually works. Our team now focuses on strategy instead of repetitive tasks."
                delay={0.3}
              />
            </SimpleGrid>
          </VStack>

          {/* CTA Section */}
          <MotionBox
            textAlign="center"
            py={14}
            my={10}
            borderRadius="2xl"
            bg="rgba(26, 32, 44, 0.7)"
            boxShadow="xl"
            borderWidth="1px"
            borderColor="gray.700"
            position="relative"
            overflow="hidden"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            backdropFilter="blur(10px)"
          >
            <Box
              position="absolute"
              inset="0"
              bg="linear-gradient(135deg, rgba(49, 151, 149, 0.05) 0%, rgba(0, 0, 0, 0) 50%, rgba(49, 151, 149, 0.05) 100%)"
              zIndex="0"
              as={motion.div}
              animate={{
                background: [
                  "linear-gradient(135deg, rgba(49, 151, 149, 0.05) 0%, rgba(0, 0, 0, 0) 50%, rgba(49, 151, 149, 0.05) 100%)",
                  "linear-gradient(135deg, rgba(49, 151, 149, 0.02) 0%, rgba(0, 0, 0, 0) 50%, rgba(49, 151, 149, 0.02) 100%)",
                  "linear-gradient(135deg, rgba(49, 151, 149, 0.05) 0%, rgba(0, 0, 0, 0) 50%, rgba(49, 151, 149, 0.05) 100%)",
                ],
              }}
              transition={{ duration: 5, repeat: Infinity }}
            />

            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              height="4px"
              bgGradient="linear(to-r, teal.500, teal.300, teal.500)"
              zIndex="1"
            />

            <Container maxW="container.md" position="relative" zIndex="2">
              <MotionHeading
                as="h2"
                size="xl"
                mb={5}
                color="white"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                viewport={{ once: true }}
                letterSpacing="-0.02em"
              >
                Ready to Transform Your Business?
              </MotionHeading>

              <MotionText
                mb={8}
                color="gray.300"
                maxW="600px"
                mx="auto"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                viewport={{ once: true }}
                fontSize="lg"
              >
                Join hundreds of forward-thinking companies already leveraging
                the power of Maximo AI
              </MotionText>

              <HStack spacing={4} justifyContent="center" flexWrap="wrap">
                <MotionButton
                  as={RouterLink}
                  to="/endpoints"
                  size="lg"
                  bgGradient="linear(to-r, teal.500, teal.400)"
                  color="white"
                  fontWeight="bold"
                  borderRadius="full"
                  px={10}
                  py={7}
                  _hover={{
                    bgGradient: "linear(to-r, teal.400, teal.300)",
                    transform: "translateY(-2px)",
                    boxShadow: "0 15px 30px -10px rgba(49, 151, 149, 0.4)",
                  }}
                  _active={{
                    bgGradient: "linear(to-r, teal.600, teal.500)",
                  }}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                  viewport={{ once: true }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  leftIcon={<FaRocket />}
                  mb={{ base: 4, md: 0 }}
                >
                  Get Started Now
                </MotionButton>

                <MotionButton
                  as="a"
                  href="https://maximoai.co/platform"
                  target="_blank"
                  size="lg"
                  bg="transparent"
                  color="teal.300"
                  fontWeight="bold"
                  borderRadius="full"
                  px={10}
                  py={7}
                  border="1px solid"
                  borderColor="teal.700"
                  _hover={{
                    bg: "rgba(49, 151, 149, 0.2)",
                    color: "teal.200",
                    transform: "translateY(-2px)",
                    boxShadow: "0 10px 20px -10px rgba(49, 151, 149, 0.3)",
                  }}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                  viewport={{ once: true }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  leftIcon={<FaKey />}
                  mb={{ base: 4, md: 0 }}
                >
                  Get API Key
                </MotionButton>
              </HStack>
            </Container>
          </MotionBox>
        </VStack>
      </Container>

      {/* Footer */}
      <Box
        py={12}
        mt={10}
        borderTop="1px"
        borderColor="gray.800"
        bg="rgba(13, 16, 20, 0.8)"
        backdropFilter="blur(10px)"
        position="relative"
        zIndex={1}
      >
        <Container maxW="container.xl">
          <Flex
            direction={{ base: "column", md: "row" }}
            align={{ base: "center", md: "flex-start" }}
            justify="space-between"
            mb={8}
          >
            <VStack
              align={{ base: "center", md: "flex-start" }}
              mb={{ base: 8, md: 0 }}
              spacing={4}
            >
              <Flex align="center">
                <MotionImage
                  src={maximoAiLogo}
                  boxSize="45px"
                  alt="Maximo AI Logo"
                  mr={3}
                  whileHover={{ rotate: 360 }}
                  transition={{ duration: 1 }}
                />
                <Text
                  fontSize="2xl"
                  fontWeight="bold"
                  color="white"
                  fontFamily="Lexend"
                  bgGradient="linear(to-r, teal.400, teal.200)"
                  backgroundClip="text"
                >
                  Maximo AI
                </Text>
              </Flex>
              <Text
                color="gray.400"
                maxW="350px"
                textAlign={{ base: "center", md: "left" }}
              >
                Empowering businesses with cutting-edge AI solutions for the
                digital age.
              </Text>
            </VStack>

            <Grid
              templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(3, 1fr)" }}
              gap={8}
              textAlign={{ base: "center", md: "left" }}
            >
              <VStack align={{ base: "center", md: "flex-start" }} spacing={3}>
                <Text color="white" fontWeight="bold" mb={2}>
                  Product
                </Text>
                <ChakraLink color="gray.400" _hover={{ color: "teal.300" }}>
                  API
                </ChakraLink>
                <ChakraLink color="gray.400" _hover={{ color: "teal.300" }}>
                  Pricing
                </ChakraLink>
                <ChakraLink color="gray.400" _hover={{ color: "teal.300" }}>
                  Documentation
                </ChakraLink>
              </VStack>

              <VStack align={{ base: "center", md: "flex-start" }} spacing={3}>
                <Text color="white" fontWeight="bold" mb={2}>
                  Company
                </Text>
                <ChakraLink color="gray.400" _hover={{ color: "teal.300" }}>
                  About
                </ChakraLink>
                <ChakraLink color="gray.400" _hover={{ color: "teal.300" }}>
                  Blog
                </ChakraLink>
                <ChakraLink color="gray.400" _hover={{ color: "teal.300" }}>
                  Careers
                </ChakraLink>
              </VStack>

              <VStack align={{ base: "center", md: "flex-start" }} spacing={3}>
                <Text color="white" fontWeight="bold" mb={2}>
                  Legal
                </Text>
                <ChakraLink
                  href="https://maximoai.co/privacy-policy"
                  isExternal
                  color="gray.400"
                  _hover={{ color: "teal.300" }}
                  display="flex"
                  alignItems="center"
                >
                  Privacy Policy
                  <Box as={FaExternalLinkAlt} size="10px" ml={1} />
                </ChakraLink>
                <ChakraLink
                  href="https://maximoai.co/terms-of-use"
                  isExternal
                  color="gray.400"
                  _hover={{ color: "teal.300" }}
                  display="flex"
                  alignItems="center"
                >
                  Terms of Use
                  <Box as={FaExternalLinkAlt} size="10px" ml={1} />
                </ChakraLink>
                <ChakraLink
                  color="gray.400"
                  _hover={{ color: "teal.300" }}
                  display="flex"
                  alignItems="center"
                >
                  <Box as={FaShieldAlt} size="12px" mr={1} />
                  Security
                </ChakraLink>
              </VStack>
            </Grid>
          </Flex>

          <Divider borderColor="gray.800" mb={6} />

          <Flex
            direction={{ base: "column-reverse", md: "row" }}
            align="center"
            justify="space-between"
          >
            <Text
              mt={{ base: 6, md: 0 }}
              fontSize="sm"
              color="gray.500"
              textAlign={{ base: "center", md: "left" }}
              fontFamily="Lexend"
            >
              © {new Date().getFullYear()} Maximo AI. All rights reserved.
            </Text>

            <Stack direction="row" spacing={4}>
              <ChakraLink href="https://x.com/AskMaximoAI" isExternal>
                <IconButton
                  icon={<FaTwitter />}
                  aria-label="Twitter"
                  size="md"
                  color="gray.400"
                  bg="gray.800"
                  _hover={{
                    color: "white",
                    bg: "#1DA1F2",
                    transform: "translateY(-2px)",
                  }}
                  borderRadius="full"
                  as={motion.button}
                  whileHover={{ scale: 1.1 }}
                />
              </ChakraLink>
              <ChakraLink href="https://t.me/maximoai" isExternal>
                <IconButton
                  icon={<FaTelegramPlane />}
                  aria-label="Telegram"
                  size="md"
                  color="gray.400"
                  bg="gray.800"
                  _hover={{
                    color: "white",
                    bg: "#0088cc",
                    transform: "translateY(-2px)",
                  }}
                  borderRadius="full"
                  as={motion.button}
                  whileHover={{ scale: 1.1 }}
                />
              </ChakraLink>
              <ChakraLink href="https://instagram.com/maximoai_co" isExternal>
                <IconButton
                  icon={<FaInstagram />}
                  aria-label="Instagram"
                  size="md"
                  color="gray.400"
                  bg="gray.800"
                  _hover={{
                    color: "white",
                    bgGradient: "linear(to-r, #E1306C, #C13584, #833AB4)",
                    transform: "translateY(-2px)",
                  }}
                  borderRadius="full"
                  as={motion.button}
                  whileHover={{ scale: 1.1 }}
                />
              </ChakraLink>
            </Stack>
          </Flex>
        </Container>
      </Box>
    </AnimatedGradientBox>
  );
};

export default Home;
